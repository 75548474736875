import 'moment/locale/it'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../app/hooks'
import CategorieMultiSelect from './CategorieMultiSelect'
import { selectQueryPagination, setQueryPagination } from '../ricercaSlice'
import ToggleButtonStoricoListiniFiltri from './ToggleButtonStoricoListiniFiltri'
import { useGetCategorieQuery } from '../ricercaAPI'
import { useState } from 'react'
import HorizontalButtonList from './lista'
import { GenericQueryPagination } from '../../../components/GenericListItem'

export function ButtonsFilters() {
    const dispatch = useDispatch()

    const { data } = useGetCategorieQuery()

    const [isOpen, setIsOpen] = useState(false)

    

    return (
        <>
            <ToggleButtonStoricoListiniFiltri
                openFilter={() => setIsOpen(true)}
            />
            <CategorieMultiSelect
                data={data?.data}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onFilter={(catMerce) => {
                    dispatch(setQueryPagination({ filter: catMerce, page: 0 }))
                }}
            />
        </>
    )
}
