import DataObjectTwoToneIcon from '@mui/icons-material/DataObjectTwoTone'
import { Button, Grid, useMediaQuery } from '@mui/material'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { snackActions } from './SnackbarUtilsConfigurator'
import { useAppSelector } from './app/hooks'
import { useGetCfQuery } from './features/ricerca/ricercaAPI'
import {
    CfNewObj,
    SETUP_VERSION_NUMBER,
    selectFullScreen,
    selectVersion,
    setFullScreen,
    setIsUpdate,
} from './features/ricerca/ricercaSlice'

export default function BreadCrumbsCliccable() {
    const { data: cfData } = useGetCfQuery()

    let fullscreen = useAppSelector(selectFullScreen)

    const cf: CfNewObj | null = cfData != null ? cfData?.data.cf : null

    let steps = ['Iniziamo 🏁', 'Offerte 👀', 'Riepiloga 🤓', 'Consegna ⤴️', 'Fatta 🎉']

    const location = useLocation()

    const navigate = useNavigate()

    let defaultStep

    if (cf?.cfCliUser.FLAG_WEB_OFFERTE != 1) {
        steps = ['Iniziamo 🏁', 'Riepiloga 🤓', 'Consegna ⤴️', 'Fatta 🎉']

        switch (location.pathname) {
            case '/':
                defaultStep = 0
                break
            case '/carrello':
                defaultStep = 1
                fullscreen = false
                break
            case '/destinazione':
                defaultStep = 2
                fullscreen = false
                break
            case '/fatta':
                defaultStep = 3
                fullscreen = false
                break
        }
    } else {
        switch (location.pathname) {
            case '/':
                defaultStep = 0
                break
            case '/offerte':
                defaultStep = 1
                break
            case '/carrello':
                defaultStep = 2
                fullscreen = false
                break
            case '/destinazione':
                defaultStep = 3
                fullscreen = false
                break
            case '/fatta':
                defaultStep = 4
                fullscreen = false
                break
        }
    }

    const handleStep = (step: number) => () => {
        if (cf?.cfCliUser.FLAG_WEB_OFFERTE != 1) {
            switch (step) {
                case 0:
                    navigate('/')
                    break
                case 1:
                    navigate('/carrello')
                    break
                case 2:
                    navigate('/destinazione')
                    break
                case 3:
                    snackActions.warning(
                        "Non dire 😼 se non ce l'hai nel sacco. Verifica di aver aggiunto qualcosa nel carrello e indica l'ora e il luogo di consegna!!!"
                    )
                    // non è a caso vuoto... serve evitare il click - vi vado dalla response dell'ordine
                    break
                default:
                    navigate('/')
            }
        } else {
            switch (step) {
                case 0:
                    navigate('/')
                    break
                case 1:
                    navigate('/offerte')
                    break
                case 2:
                    navigate('/carrello')
                    break
                case 3:
                    navigate('/destinazione')
                    break
                case 4:
                    snackActions.warning(
                        "Non dire 😼 se non ce l'hai nel sacco. Verifica di aver aggiunto qualcosa nel carrello e indica l'ora e il luogo di consegna!!!"
                    )
                    // non è a caso vuoto... serve evitare il click - vi vado dalla response dell'ordine
                    break
                default:
                    navigate('/')
            }
        }
    }

    const isXs = useMediaQuery((theme: any) => theme.breakpoints.only('xs'))

    const version = useAppSelector(selectVersion)

    const remoteVersionNumber = cfData?.data?.VERSIONE || SETUP_VERSION_NUMBER

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setFullScreen(false))
    }, [location])

    return fullscreen == false && defaultStep != null ? (
        <>
            <Stepper nonLinear activeStep={defaultStep} alternativeLabel={isXs}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {remoteVersionNumber != version.vNumber &&
                (remoteVersionNumber != SETUP_VERSION_NUMBER && (
                    <Grid item xs={12} textAlign="center">
                        <Button
                            sx={{ m: 2 }}
                            color="error"
                            size="large"
                            variant="contained"
                            startIcon={<>🔄</>}
                            onClick={() => {
                                dispatch(setIsUpdate({ vNumber: remoteVersionNumber }))
                            }}
                        >
                            {`Aggiornamento necessario ${remoteVersionNumber}`}
                        </Button>
                    </Grid>
                ))}
        </>
    ) : (
        <></>
    )
}
