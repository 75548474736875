import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ToggleButtonGroup,
    useTheme
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import MuiToggleButton from '@mui/material/ToggleButton'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { CatMerceObj, selectQueryPagination } from '../ricercaSlice'
import { useAppSelector } from '../../../app/hooks'

interface Props {
    onFilter: (catMerce: string[]) => void
    onClose: () => void
    isOpen: boolean
    data?: CatMerceObj[]
}
export default function CategorieMultiSelect({ onFilter, onClose, isOpen, data }: Props) {

    const queryPagination = useAppSelector(selectQueryPagination)

    const filter = queryPagination.filter || []
    
    const handleFormat = (event: React.MouseEvent<HTMLElement>, catMerce: string[]) => {
        onFilter(catMerce)
    }

    const theme = useTheme()

    const ToggleButton = styled(MuiToggleButton)(({ color }: any) => ({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    }))

    return (
        <Dialog keepMounted={true} maxWidth="lg" fullWidth={true} open={isOpen}>
            <DialogTitle>Filtri</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Seleziona ora uno o più voci per filtrare la categoria merciologica
                </DialogContentText>
                <div style={{ overflowX: 'auto' }}>
                    <ToggleButtonGroup value={filter} onChange={handleFormat}>
                        {data?.map((value: CatMerceObj) => {
                            return (
                                <ToggleButton key={value.COD_CAT} style={{ flexWrap: 'wrap' }} value={value.COD_CAT}>
                                    <Avatar sx={{ mr: 1 }}>{value.icon}</Avatar>
                                    {value.DES_CAT}
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => onClose()}>
                    chiudi
                </Button>
            </DialogActions>
        </Dialog>
    )
}
