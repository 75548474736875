import { Badge, Button, Stack, Typography, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import MuiToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import 'moment/locale/it'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCfQuery } from '../ricercaAPI'
import { CfNewObj, selectQueryPagination, setQueryPagination } from '../ricercaSlice'
import download from './svg/download.svg'
import filter from './svg/filter.svg'
import heart from './svg/heart.svg'
import inviati from './svg/inviati.svg'
import listino from './svg/listino.svg'
import { useAppSelector } from '../../../app/hooks'
import { GenericQueryPagination } from '../../../components/GenericListItem'
import { useDispatch } from 'react-redux'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    //   [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    //     {
    //       marginLeft: -1,
    //       borderLeft: '1px solid transparent',
    //     },
}))

export interface Props {
    openFilter: () => void
}

export default function ToggleButtonStoricoListiniFiltri({ openFilter }: Props) {
    const dispatch = useDispatch()

    const queryPagination = useAppSelector(selectQueryPagination)

    const count = queryPagination.filter?.length

    const handleChangeToggleButtons = (event: React.MouseEvent<HTMLElement>, newHistory: string) => {
        switch (newHistory) {
            case 'history':
                dispatch(setQueryPagination({ history: !queryPagination.history, listino: false, page: 0 }))
                break
            case 'listino':
                dispatch(setQueryPagination({ history: false, listino: !queryPagination.listino, page: 0 }))
                break
            default:
                dispatch(setQueryPagination({ history: false, listino: false, page: 0 }))
                break
        }
    }

    const handleClickDownload = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/artAna/export', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.ok) {
                const today = moment(new Date()).format('YYYYMMDD_HHmm')
                const blob = await response.blob()
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = `listino_${today}.pdf`
                link.click()
                URL.revokeObjectURL(url)
            } else {
                console.error('Failed to download file')
            }
        } catch (error) {
            console.error('Error downloading file:', error)
        }
    }

    const theme = useTheme()

    const ToggleButton = styled(MuiToggleButton)(({ color }: any) => ({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    }))

    const { data: cfData } = useGetCfQuery()

    const cf: CfNewObj | null = cfData != null ? cfData?.data.cf : null

    const navigate = useNavigate()

    const handleClickGoToInviati = () => {
        navigate('/inviati')
    }

    return (
        <>
            <Paper
                sx={{
                    overflow: 'auto',
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    pb: 0.5,
                    pt: 0.5,
                }}
            >
                <Button variant="text" size="small" onClick={handleClickGoToInviati}>
                    <Stack direction="column" textAlign="center" alignItems="center">
                        <img src={inviati} alt="" style={{ width: 40 }} />
                        <Typography variant="caption">Inviati</Typography>
                    </Stack>
                </Button>
                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                <StyledToggleButtonGroup exclusive onChange={handleChangeToggleButtons}>
                    <ToggleButton value="history" aria-label="left aligned" selected={queryPagination.history}>
                        <Stack direction="column" textAlign="center" alignItems="center">
                            <img src={heart} alt="" style={{ width: 40 }} />
                            <Typography variant="caption">preferiti</Typography>
                        </Stack>
                    </ToggleButton>
                    {cf?.cfCliUser.FLAG_WEB_LISTINO == 1 && (
                        <ToggleButton value="listino" selected={queryPagination.listino}>
                            <Stack direction="column" textAlign="center" alignItems="center">
                                <img src={listino} alt="" style={{ width: 40 }} />
                                <Typography variant="caption">listino</Typography>
                            </Stack>
                        </ToggleButton>
                    )}
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                <StyledToggleButtonGroup value={queryPagination.filter?.length ?? 0} onChange={openFilter} exclusive>
                    <ToggleButton
                        value="filter"
                        aria-label="centered"
                        selected={queryPagination?.filter != null && queryPagination.filter.length > 0}
                    >
                        <Badge color="secondary" badgeContent={count}>
                            <Stack direction="column" textAlign="center" alignItems="center">
                                <img src={filter} alt="" style={{ width: 40 }} />
                                <Typography variant="caption">filtro</Typography>
                            </Stack>
                        </Badge>
                    </ToggleButton>
                </StyledToggleButtonGroup>
                {cf?.cfCliUser.FLAG_WEB_LISTINO == 1 && (
                    <>
                        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                        <Button variant="text" size="small" onClick={handleClickDownload}>
                            <Stack direction="column" textAlign="center" alignItems="center">
                                <img src={download} alt="" style={{ width: 40 }} />
                                <Typography variant="caption">listino</Typography>
                            </Stack>
                        </Button>
                    </>
                )}
            </Paper>
        </>
    )
}
