import {
    Box,
    Color,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    OutlinedInput,
    Pagination,
    Stack,
    Typography,
    styled,
    useTheme,
} from '@mui/material'
import 'moment/locale/it'
import { useEffect, useRef } from 'react'

import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone'
import { GenericListItems } from '../../components/GenericListItem'
import { ArtAnaItem } from '../ricerca/ArtAnaItem'
import { useGetListiniOfferteQuery } from '../ricerca/ricercaAPI'
import { ArtAna, PAGE_SIZE, START_PAGE, selectFullScreen, selectQueryPaginationOfferte, setQueryPaginationOfferte } from '../ricerca/ricercaSlice'
import { useAppSelector } from '../../app/hooks'
import { useDispatch } from 'react-redux'

const ArtAnaList = GenericListItems<ArtAna>()

export function Offerte() {

    const dispatch = useDispatch()

    const queryPagination = useAppSelector(selectQueryPaginationOfferte)

    const secondBlockRef = useRef<any>(null)

    const theme = useTheme()

    const CustomScrollbarDiv = styled(List)`
        --sb-track-color: ${theme.palette.secondary.light};
        --sb-thumb-color: ${theme.palette.primary.main};
        --sb-size: 20px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: var(--sb-size);
        }

        &::-webkit-scrollbar-track {
            background: var(--sb-track-color);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--sb-thumb-color);
            border-radius: 10px;
            border: 1px solid ${theme.palette.text.primary};
        }

        @supports not selector(::-webkit-scrollbar) {
            scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
        }
    `

    useEffect(() => {
        if ('virtualKeyboard' in navigator) {
            ; (navigator.virtualKeyboard as any).addEventListener('geometrychange', (event: any) => {
                const { x, y, width, height } = event.target.boundingRect
                console.log('Virtual keyboard geometry changed:', x, y, width, height)
            })
            const virtualKeyboard = navigator.virtualKeyboard as any
            virtualKeyboard.overlaysContent = true // Opt out of the automatic handling.
        }

        window.addEventListener('resize', () => {
            // For the rare legacy browsers that don't support it
            if (!window.visualViewport) {
                return
            }

            // console.log(window.visualViewport.height)
        })

        return () => {
            window.removeEventListener('resize', () => {
                // For the rare legacy browsers that don't support it
                if (!window.visualViewport) {
                    return
                }

                // console.log(window.visualViewport.height)
            })
        }
    }, [])

    const fullscreen = useAppSelector(selectFullScreen)

    // PAGINATION

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const page = value - 1
        dispatch(setQueryPaginationOfferte({ ...queryPagination, page }))
        // secondBlockRef.current.scrollTop = 0
    }

    const { data, isLoading } = useGetListiniOfferteQuery({
        ...queryPagination
    })

    const height = fullscreen == true ? 'calc(95vh - 0px)' : 'calc(85vh - 100px)'

    // DESIGN

    return (
        <>
            <Stack spacing={1} direction="row" sx={{ minWidth: '100%' }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Ricerca libera</InputLabel>
                    <OutlinedInput
                        size="medium"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    color="primary"
                                    edge="end"
                                    onClick={() => {
                                        dispatch(
                                            setQueryPaginationOfferte({
                                                ...queryPagination,
                                                page: START_PAGE,
                                                search: '',
                                            })
                                        )
                                    }}
                                >
                                    <BackspaceTwoToneIcon color="secondary" />
                                </IconButton>
                            </InputAdornment>
                        }
                        inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            autoCapitalize: 'off',
                            spellCheck: 'false',
                        }}
                        label="Ricerca libera"
                        sx={{ userSelect: 'none', '&::-webkit-user-select': 'none' }}
                        onChange={(e) => {
                            dispatch(
                                setQueryPaginationOfferte({
                                    ...queryPagination,
                                    page: 0,
                                    search: e.target.value,
                                })
                            )
                        }}
                        onFocus={(e) => {
                            dispatch(
                                setQueryPaginationOfferte({
                                    ...queryPagination,
                                    page: 0,
                                    search: '',
                                })
                            )
                        }}
                        value={queryPagination?.search}
                    />
                </FormControl>
            </Stack>
            <Grid container spacing={2} textAlign="center">
                <Grid item xs={12}>
                    <Typography fontStyle="italic" variant="h6">
                        {data?.listini.listiniOfferte?.DES_TIPO_LIST}
                    </Typography>
                </Grid>
            </Grid>
            <CustomScrollbarDiv
                ref={secondBlockRef}
                sx={{
                    scrollBehavior: 'smooth',
                    overflowY: 'auto',
                    height: height,
                    pr: 1,
                }}
            >
                {data != null && (
                    <ArtAnaList
                        isLoading={isLoading}
                        data={data.data}
                        render={(artAna) => (
                            <ArtAnaItem
                                artUmList={artAna.artUmList}
                                carrelloAppoggio={artAna.carrelloAppoggio}
                                key={artAna.COD_ART}
                                _COD_ART={artAna.COD_ART}
                                _DES_RIGA={artAna.DES_ART}
                                _DES_CAT={artAna.DES_CAT}
                                _UM_BASE={artAna.UM_BASE}
                                _PREZZO_LISTINO={artAna.PREZZO_LISTINO}
                                _COD_LIST={artAna.COD_LIST}
                                _DEFAULT_COD_LIST={artAna.DEFAULT_COD_LIST}
                                _WEB_DISABILITATO={artAna.WEB_DISABILITATO}
                                _WEB_NOTA_1={artAna.WEB_NOTA_1}
                                _WEB_NOTA_2={artAna.WEB_NOTA_2}
                                _WEB_NOTA_3={artAna.WEB_NOTA_3}
                                listini={data.listini}
                            />
                        )}
                    />
                )}
                <Pagination
                    sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}
                    size="large"
                    variant="outlined"
                    shape="rounded"
                    unselectable="on"
                    color="primary"
                    count={data == null ? 0 : Math.round(data.rowCount / PAGE_SIZE) + 1}
                    page={queryPagination.page + 1}
                    onChange={handleChange}
                />
                <Box sx={{ height: 50 }}></Box>
            </CustomScrollbarDiv>
        </>
    )
}
