import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone'
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone'
import {
    Badge,
    Box,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    OutlinedInput,
    Pagination,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    styled,
    useTheme,
} from '@mui/material'
import 'moment/locale/it'
import { useRef, useState } from 'react'
import { GenericListItems, GenericQueryPagination } from '../../components/GenericListItem'
import { useGetRicercaArtAnaQuery } from '../ricerca/ricercaAPI'
import { ArtAna, START_PAGE } from '../ricerca/ricercaSlice'
import ImageItem from './ImageItem'
import { ArtAnaItem } from '../ricerca/ArtAnaItem'

const ArtAnaList = GenericListItems<ArtAna>()

export function Images() {
    const windowSize = useRef([window.innerWidth, window.innerHeight])

    const secondBlockRef = useRef<any>(null)

    const PAGE_SIZE = 12

    const defaultDataSearch: GenericQueryPagination = {
        search: '',
        pageSize: PAGE_SIZE,
        page: START_PAGE,
        history: false,
        listino: false,
        filter: null,
    }

    const [dataSearch, setDataSearch] = useState<GenericQueryPagination>(defaultDataSearch)

    // PAGINATION

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setDataSearch((prevData) => {
            const page = value - 1
            return { ...prevData, page }
        })
    }

    const { data, isLoading } = useGetRicercaArtAnaQuery({
        ...dataSearch,
    })

    const [buttonsSelected, setButtonsSelected] = useState<Array<string> | null>(null)

    const handleChangeToggleButtons = (event: React.MouseEvent<HTMLElement>, newHistory: Array<string>) => {
        if (newHistory.indexOf('history') !== -1) {
            setDataSearch((prevData) => {
                setButtonsSelected(newHistory)
                return { ...defaultDataSearch, history: true }
            })
        } else {
            setDataSearch((prevData) => {
                setButtonsSelected(newHistory)
                return { ...prevData, history: false }
            })
        }

        if (newHistory.indexOf('filter') !== -1) {
        } else {
            setDataSearch((prevData) => {
                return { ...prevData, filter: null, page: 0 }
            })
        }
    }

    // DESIGN

    return (
        <>
            <Stack spacing={1} direction="row" sx={{ minWidth: '100%' }}>
                {/* <ToggleButtonGroup value={buttonsSelected} color="primary" onChange={handleChangeToggleButtons}>
                    <ToggleButton value="filter">
                        <Badge color="secondary" badgeContent={dataSearch.filter?.length}>
                            <FilterAltTwoToneIcon />
                        </Badge>
                    </ToggleButton>
                </ToggleButtonGroup> */}
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-ricerca-images">Ricerca libera</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-ricerca-images"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={() => {
                                        setDataSearch((prevData) => defaultDataSearch)
                                    }}
                                >
                                    <BackspaceTwoToneIcon color="secondary" />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Ricerca libera"
                        onChange={(e) => {
                            setDataSearch((prevData) => {
                                return {
                                    ...defaultDataSearch,
                                    search: e.target.value,
                                }
                            })
                        }}
                        value={dataSearch.search}
                    />
                </FormControl>
            </Stack>
            <Grid
                container
                spacing={1}
                sx={{ mt: 2 }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                {data != null && (
                    <ArtAnaList
                        isLoading={isLoading}
                        data={data?.data}
                        render={(artAna) => (
                            <Grid key={artAna.COD_ART} item xs={12} sm={6} md={4} lg={3}>
                                <ImageItem artAna={artAna} />
                            </Grid>
                        )}
                    />
                )}
                <Pagination
                    sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}
                    size="large"
                    variant="outlined"
                    shape="rounded"
                    unselectable="off"
                    color="primary"
                    count={data == null ? 0 : Math.round(data.rowCount / PAGE_SIZE) + 1}
                    page={dataSearch.page + 1}
                    onChange={handleChange}
                />
                <Box sx={{ height: 50 }}></Box>
            </Grid>
        </>
    )
}
