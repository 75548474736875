import { ThemeProvider } from '@emotion/react'
import Brightness4TwoToneIcon from '@mui/icons-material/Brightness4TwoTone'
import { Box, createTheme, CssBaseline, FormControlLabel, Grid, Switch, ThemeOptions } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { itIT } from '@mui/x-date-pickers/locales'
import { useEffect, useState } from 'react'
import { createBrowserRouter, Outlet, RouteObject, RouterProvider } from 'react-router-dom'
import { useAppSelector } from './app/hooks'
import BreadCrumbsCliccable from './BreadCrumbsCliccable'
import ErrorPage from './error-page'
import { Carrello } from './features/carrello/Carrello'
import { Destinazine } from './features/destinazione/Destinazione'
import { Fatta } from './features/fatta/Fatta'
import { Inviati } from './features/inviati/Inviati'
import ChangePassworkToken from './features/login/ChangePassworkToken'
import LoginSide from './features/login/LoginSide'
import PrivateRoute from './features/login/PrivateRoute'
import PrivateRouteRoleAzienda from './features/login/PrivateRouteRoleAzienda'
import RegistrationToken from './features/login/RegistrationToken'
import { ResetPassword } from './features/login/ResetPassword'
import { Offerte } from './features/offerte/Offerte'
import { Ricerca } from './features/ricerca/Ricerca'
import { selectFullScreen } from './features/ricerca/ricercaSlice'
import { Images } from './features/uploadImages/Images'
import ResponsiveDrawer from './ResponsiveDrawer'
import './include.css'

const cGreen = green[900]
const cRed = red[300]

// Define theme settings
const light: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: cGreen,
        },
        secondary: {
            main: cRed,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: 18,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    marginTop: 4,
                    width: '99%',
                    border: `2px solid ${cGreen}`,
                    backgroundColor: 'white',
                    boxShadow: `0px 5px 8px ${cGreen}`,
                },
            },
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 10,
    },
}

const dark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: cGreen,
        },
        secondary: {
            main: cRed,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: 18,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    marginTop: 4,
                    width: '99%',
                    border: `2px solid ${cGreen}`,
                    backgroundColor: 'black',
                    boxShadow: `0px 5px 8px ${cGreen}`,
                },
            },
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 10,
    },
}

function App() {
    // The light theme is used by default
    const [isDarkTheme, setIsDarkTheme] = useState(false)

    const changeTheme = () => {
        setIsDarkTheme(!isDarkTheme)
    }

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            setIsDarkTheme(e.matches)
        })
    }, [])

    const theme = isDarkTheme ? createTheme(dark, itIT) : createTheme(light, itIT)

    const offerte: RouteObject = {
        path: '/offerte',
        element: (
            <PrivateRoute>
                <Offerte />
            </PrivateRoute>
        ),
    }

    const fullscreen = useAppSelector(selectFullScreen)

    const router = createBrowserRouter([
        {
            path: '/login',
            element: <LoginSide />,
        },
        {
            path: '/registrationToken',
            element: <RegistrationToken />,
        },
        {
            path: '/changePasswordToken',
            element: <ChangePassworkToken />,
        },
        {
            path: '/resetPassword',
            element: <ResetPassword />,
        },
        {
            path: '/',
            element:
                fullscreen == false ? (
                    <ResponsiveDrawer
                        ToggleTheme={
                            <FormControlLabel
                                control={
                                    <>
                                        <Switch checked={isDarkTheme} onChange={changeTheme} />
                                        <Brightness4TwoToneIcon />
                                    </>
                                }
                                label=""
                                labelPlacement="start"
                                sx={{ flexGrow: 1, mr: 2 }}
                            />
                        }
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            id="back-to-top-anchor"
                            pl={1}
                            pr={1}
                            gap={1}
                            sx={{ pt: 12 }}
                        >
                            <Grid item xs={12}>
                                <BreadCrumbsCliccable />
                            </Grid>
                            <Grid item xs={12}>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </ResponsiveDrawer>
                ) : (
                    <Box pl={1} pr={1}>
                        <Outlet />
                    </Box>
                ),
            children: [
                {
                    index: true,
                    path: '/',
                    element: (
                        <PrivateRoute>
                            <Ricerca />
                        </PrivateRoute>
                    ),
                },
                offerte,
                {
                    path: '/fotoArticoli',
                    element: (
                        <PrivateRouteRoleAzienda>
                            <Images />
                        </PrivateRouteRoleAzienda>
                    ),
                },
                {
                    path: '/carrello',
                    element: (
                        <PrivateRoute>
                            <Carrello />
                        </PrivateRoute>
                    ),
                },
                {
                    path: '/destinazione',
                    element: (
                        <PrivateRoute>
                            <Destinazine />
                        </PrivateRoute>
                    ),
                },
                {
                    path: '/inviati',
                    element: (
                        <PrivateRoute>
                            <Inviati />
                        </PrivateRoute>
                    ),
                },
                {
                    path: '/fatta',
                    element: (
                        <PrivateRoute>
                            <Fatta />
                        </PrivateRoute>
                    ),
                },
            ],
            errorElement: <ErrorPage />,
        },
    ])

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {/* <Footer /> */}
                <RouterProvider router={router} />
            </ThemeProvider>
        </>
    )
}

export default App
