import CloseFullscreenTwoToneIcon from '@mui/icons-material/CloseFullscreenTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ZoomInTwoToneIcon from '@mui/icons-material/ZoomInTwoTone'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MobileStepper from '@mui/material/MobileStepper'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import * as React from 'react'
import { useState } from 'react'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { GenericDialog } from '../../components/GenericDialog'
import { useDeleteImageMutation } from '../ricerca/ricercaAPI'
import { ArtImageObj, ImageObjSlicer, TIPO_IMAGE } from '../ricerca/ricercaSlice'

import './swipe.css'

const StyledDialogContent = styled(DialogContent)({
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    // height: '100%',
    // overflow: 'hidden',
})

const StyledImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
})

export interface PropsFullScreeDialog {
    onClose: () => void
    imageUrl: string | null
}

const FullScreenDialog = ({ onClose, imageUrl }: PropsFullScreeDialog) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const imgRef = React.useRef<any>()
    const onUpdate = React.useCallback(({ x, y, scale }: any) => {
        let { current: img } = imgRef

        if (img) {
            const value = make3dTransformValue({ x, y, scale })

            img?.style?.setProperty('transform', value)
        }
    }, [])

    return (
        <Dialog
            fullScreen={fullScreen}
            open={imageUrl != null}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                style: {
                    height: '80vh', // Altezza del dialog
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h6">...doppio tap sull'immagine per ingrandire</Typography>
                <Button
                    color="primary"
                    variant="contained"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseFullscreenTwoToneIcon />
                </Button>
            </DialogTitle>
            <StyledDialogContent>
                <QuickPinchZoom onUpdate={onUpdate}>
                    <StyledImage ref={imgRef} src={imageUrl != null ? imageUrl : ''} alt="" />
                </QuickPinchZoom>
            </StyledDialogContent>
        </Dialog>
    )
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const IconContainer = styled('div')({
    position: 'relative',
    display: 'inline-block',
})

const IconShadow = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '80%',
    filter: 'blur(1px)',
    zIndex: 1,
    background: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    borderRadius: '50%',
}))

const IconStyled = styled(ZoomInTwoToneIcon)(({ theme }) => ({
    position: 'relative',
    zIndex: 2,
    fontSize: 30,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
}))

const Image = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
})

export interface Props {
    images: Array<ImageObjSlicer> | []
    data: Array<ArtImageObj> | []
    readonly?: boolean
}

function ImageSlider({ data, images, readonly }: Props) {
    const [open, setOpen] = useState<string | null>(null)

    const handleClickOpen = (url: string) => {
        setOpen(url)
    }

    const handleClose = () => {
        setOpen(null)
    }

    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = images?.length

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (step: number) => {
        setActiveStep(step)
    }

    const [deleteImage, { isLoading, error }] = useDeleteImageMutation()
    const [deleteArtImageObj, setDeleteArtImageObj] = useState<ArtImageObj | null>(null)
    function handleDelete(): void {
        setDeleteArtImageObj((prev) => data[activeStep])
    }

    return (
        maxSteps != null &&
        maxSteps > 0 && (
            <>
                <GenericDialog
                    siTxt="si"
                    noTxt="chiudi"
                    isOpen={deleteArtImageObj != null}
                    title="Cancella immagine"
                    content={
                        <Box
                            component="img"
                            sx={{
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                            src={images[activeStep]?.imgPath + '&IMAGE_WIDTH=400&IMAGE_HEIGHT=300'}
                            alt={images[activeStep]?.label}
                        />
                    }
                    onConfirm={async function () {
                        try {
                            const respo = await deleteImage({
                                id: deleteArtImageObj?.ART_IMAGE_ID!!,
                                TIPO: TIPO_IMAGE.ART_ANA,
                            }).unwrap()
                            setDeleteArtImageObj(null)
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                    onCancel={function (): void {
                        setDeleteArtImageObj(null)
                    }}
                />
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents={true}
                    style={{ width: 100, height: 85 }}
                    slideStyle={{ overflow: 'hidden' }}
                >
                    {images?.map((step, index) => {
                        return (
                            <IconContainer key={step.imgPath} onClick={() => handleClickOpen(step.imgPath)}>
                                <IconShadow />
                                <IconStyled />
                                <Image
                                    style={{
                                        width: 100,
                                        height: 85,
                                        objectFit: 'cover',
                                        objectPosition: '0 auto',
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                        borderBottomRightRadius: 10,
                                    }}
                                    src={step.imgPath + '&IMAGE_WIDTH=300'}
                                    alt={step.label}
                                />
                            </IconContainer>
                        )
                    })}
                </AutoPlaySwipeableViews>
                <FullScreenDialog
                    onClose={handleClose}
                    imageUrl={open} // Sostituisci con l'URL dell'immagine desiderata
                />
                {readonly == undefined && (
                    <IconButton aria-label="delete" size="large" onClick={() => handleDelete()}>
                        <DeleteForeverTwoToneIcon fontSize="inherit" />
                    </IconButton>
                )}
                {readonly == undefined && maxSteps > 1 && (
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        sx={{ maxWidth: 10 }}
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            </Button>
                        }
                    />
                )}
            </>
        )
    )
}

export default ImageSlider
