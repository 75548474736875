import CloseFullscreenTwoToneIcon from '@mui/icons-material/CloseFullscreenTwoTone'
import ZoomInTwoToneIcon from '@mui/icons-material/ZoomInTwoTone'
import { Dialog, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import * as React from 'react'
import { useState } from 'react'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { ImageObjSlicer } from '../ricerca/ricercaSlice'

import './swipe.css'

const StyledDialogContent = styled(DialogContent)({
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
})

const StyledImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
})

export interface PropsFullScreeDialog {
    onClose: () => void
    imageUrl: string | null
}

const FullScreenDialog = ({ onClose, imageUrl }: PropsFullScreeDialog) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const imgRef = React.useRef<any>()
    const onUpdate = React.useCallback(({ x, y, scale }: any) => {
        let { current: img } = imgRef

        if (img) {
            const value = make3dTransformValue({ x, y, scale })

            img?.style?.setProperty('transform', value)
        }
    }, [])

    return (
        <Dialog
            fullScreen={fullScreen}
            open={imageUrl != null}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                style: {
                    height: '80vh', // Altezza del dialog
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h6">...doppio tap sull'immagine per ingrandire</Typography>
                <Button
                    color="primary"
                    variant="contained"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseFullscreenTwoToneIcon />
                </Button>
            </DialogTitle>
            <StyledDialogContent>
                <QuickPinchZoom onUpdate={onUpdate}>
                    <StyledImage ref={imgRef} src={imageUrl != null ? imageUrl : ''} alt="" />
                </QuickPinchZoom>
            </StyledDialogContent>
        </Dialog>
    )
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const IconContainer = styled('div')({
    position: 'relative',
    display: 'inline-block',
})

const IconShadow = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 1,
    left: 1,
    width: 30,
    height: 30,
    filter: 'blur(1px)',
    zIndex: 1,
    background: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    borderRadius: '50%',
}))

const IconStyled = styled(ZoomInTwoToneIcon)(({ theme }) => ({
    position: 'fixed',
    zIndex: 2,
    fontSize: 30,
    top: 1,
    left: 1,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
}))

export interface Props {
    images: Array<ImageObjSlicer> | []
}

function ImageSliderReadOnly({ images }: Props) {
    const [open, setOpen] = useState<string | null>(null)

    const handleClickOpen = (url: string) => {
        setOpen(url)
    }

    const handleClose = () => {
        setOpen(null)
    }

    return (
        <>
            <AutoPlaySwipeableViews>
                {images?.map((step) => {
                    return (
                        <React.Fragment key={step.imgPath}>
                            <Box
                                key={step.imgPath}
                                onClick={() => handleClickOpen(step.imgPath)}
                                sx={{
                                    overflow: 'hidden',
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    borderBottomRightRadius: 10,
                                    height: 100,
                                    backgroundImage: `url('${step.imgPath}&IMAGE_WIDTH=300')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <IconShadow />
                            <IconStyled />
                        </React.Fragment>
                    )
                })}
            </AutoPlaySwipeableViews>
            <FullScreenDialog
                onClose={handleClose}
                imageUrl={open} // Sostituisci con l'URL dell'immagine desiderata
            />
        </>
    )
}

export default ImageSliderReadOnly
