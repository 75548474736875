import AssistantDirectionTwoToneIcon from '@mui/icons-material/AssistantDirectionTwoTone'
import DataObjectTwoToneIcon from '@mui/icons-material/DataObjectTwoTone'
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone'
import LocalFloristTwoToneIcon from '@mui/icons-material/LocalFloristTwoTone'
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'
import MenuIcon from '@mui/icons-material/Menu'
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone'
import SavingsTwoToneIcon from '@mui/icons-material/SavingsTwoTone'
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone'
import ShoppingBasketTwoToneIcon from '@mui/icons-material/ShoppingBasketTwoTone'
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone'
import { Button, ListItemButton, useScrollTrigger, Zoom } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from './app/hooks'
import { CarrelloQuantita } from './features/carrelloQuantita/CarrelloQuantita'
import { useGetCfQuery, useLogoutPostMutation } from './features/ricerca/ricercaAPI'
import {
    CfNewObj,
    LoginRequest,
    selectUser,
    selectVersion,
    setIsUpdate,
    SETUP_VERSION_NUMBER,
} from './features/ricerca/ricercaSlice'
import { min } from 'moment'

const drawerWidth = 340

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
    children: React.ReactElement
    ToggleTheme?: React.ReactElement
}

function ScrollTop(props: Props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    })

    const handleClickAnchor = (event: React.MouseEvent<HTMLDivElement> | null) => {
        let anchor
        if (event) {
            anchor = ((event?.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')
        } else {
            anchor = document.querySelector('#back-to-top-anchor')
        }

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }

    return (
        <Zoom in={trigger}>
            <Box onClick={handleClickAnchor} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                {children}
            </Box>
        </Zoom>
    )
}

export default function ResponsiveDrawer(props: Props) {
    const user = useAppSelector(selectUser)

    const { window, children, ToggleTheme } = props
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const navigate = useNavigate()

    const location = useLocation()

    const [
        postLogout, // This is the mutation trigger
        { isLoading, error }, // This is the destructured mutation result
    ] = useLogoutPostMutation()

    const handleClick = async () => {
        const rest = await postLogout({
            E_MAIL_CF: user?.cf.E_MAIL_CF,
        } as LoginRequest).unwrap()

        setTimeout(() => {
            navigate('/login')
        }, 500)
    }

    const { data: cfData } = useGetCfQuery()

    const cf: CfNewObj | null = cfData?.data?.cf ?? null

    const remoteVersionNumber = cfData?.data?.VERSIONE || SETUP_VERSION_NUMBER

    const dispatch = useDispatch()

    const versione = useAppSelector(selectVersion)

    const drawer = (
        <div>
            <List>
                <ListItem>
                    <img style={{ width: drawerWidth * 0.85 }} src={'/logo192.png'} />
                </ListItem>
                <ListItem>{ToggleTheme}</ListItem>
                <ListItem component={Link} to="/" button onClick={handleDrawerToggle}>
                    <ListItemIcon>{<StorefrontTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Negozio'} />
                </ListItem>
                {cf?.cfCliUser.FLAG_WEB_OFFERTE == 1 && (
                    <ListItem component={Link} to="/offerte" button onClick={handleDrawerToggle}>
                        <ListItemIcon>{<SavingsTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary={'Offerte'} />
                    </ListItem>
                )}
                <ListItem component={Link} to="/carrello" button onClick={handleDrawerToggle}>
                    <ListItemIcon>{<ShoppingBasketTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Carrello'} />
                </ListItem>
                <ListItem component={Link} to="/inviati" button onClick={handleDrawerToggle}>
                    <ListItemIcon>{<FactCheckTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Inviati'} />
                </ListItem>
            </List>
            {user?.azienda != null && (
                <List>
                    <Divider />
                    <ListItem component={Link} to="/fotoArticoli" button onClick={handleDrawerToggle}>
                        <ListItemIcon>{<LocalFloristTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary={'Foto articoli'} />
                    </ListItem>
                </List>
            )}
            <Divider />
            {user?.cf != null && (
                <ListItemButton component="a" onClick={handleClick}>
                    <ListItemIcon>{<LockTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={`${user.cf.COD_CF} ${user.cf.E_MAIL_CF}`} secondary="logout" />
                </ListItemButton>
            )}
            <Divider />
            <List>
                <ListItem>
                    <ListItemIcon>{<DataObjectTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={`${versione.vNumber}`} secondary={'Versione webApp'} />
                    <Button
                        color={remoteVersionNumber == versione.vNumber ? 'primary' : 'error'}
                        size="small"
                        variant="contained"
                        onClick={() => {
                            dispatch(setIsUpdate({ vNumber: remoteVersionNumber }))
                        }}
                    >
                        AGGIORNA APP
                    </Button>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem target={'_blank'} component="a" href="https://www.prosyt.it">
                    <ListItemIcon>{<PaidTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Prosyt'} />
                </ListItem>
            </List>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    let strLocation = ''
    let icon = <></>

    switch (location.pathname) {
        case '/':
            strLocation = 'Negozio'
            icon = <StorefrontTwoToneIcon fontSize="medium" color="primary" />
            break
        case '/offerte':
            strLocation = 'Offerte'
            icon = <SavingsTwoToneIcon fontSize="medium" color="primary" />
            break
        case '/inviati':
            strLocation = 'Inviati'
            icon = <FactCheckTwoToneIcon fontSize="medium" color="primary" />
            break
        case '/carrello':
            strLocation = 'Carrello'
            icon = <ShoppingBasketTwoToneIcon fontSize="medium" color="primary" />
            break
        case '/destinazione':
            strLocation = 'Consegna'
            icon = <AssistantDirectionTwoToneIcon fontSize="medium" color="primary" />
            break
        case '/impostazioni':
            strLocation = 'Impostazioni'
            icon = <SettingsApplicationsTwoToneIcon fontSize="medium" color="primary" />
            break
        case '/fotoArticoli':
            strLocation = 'Foto articoli'
            icon = <LocalFloristTwoToneIcon fontSize="medium" color="primary" />
            break
    }

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 0 }}>
                        <MenuIcon color="primary" />
                    </IconButton>
                    {icon}
                    <Typography
                        sx={{ ml: 2, flexGrow: 1, textTransform: 'uppercase', fontWeight: 'bold' }}
                        variant="h5"
                        noWrap
                        component="div"
                    >
                        {/* {strLocation} */}
                    </Typography>
                    {user?.cf != null && <CarrelloQuantita />}
                </Toolbar>
                {location.pathname === '/offerte' && (
                    <img
                        src={'/background/tag.svg'}
                        style={{
                            top: '35px',
                            height: '70px',
                            right: '25px',
                            position: 'absolute',
                            zIndex: 0,
                        }}
                    />
                )}
                {/* <img
                    src={'/background/awning_red.svg'}
                    style={{
                        top: 0,
                        height: 100,
                        left: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: -1,
                        margin: '0 auto',
                    }}
                /> */}
                <Box
                    style={{
                        minHeight: 48,
                        maxHeight: 48,
                        top: 0,
                        left: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: -1,
                        margin: '0 auto',
                        filter: 'drop-shadow(1px 1px 1px black)',
                    }}
                >
                    <img
                        style={{
                            minHeight: '100%',
                            maxHeight: '100%',
                            top: 5,
                            left: 0,
                            right: 0,
                            position: 'absolute',
                            zIndex: -1,
                            margin: '0 auto',
                        }}
                        src={'/logo192.png'}
                    />
                </Box>
            </AppBar>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Box sx={{ mt: 1 }}>{children}</Box>
        </>
    )
}
